<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Danh sách nguồn đơn hàng'">
          <template v-slot:toolbar>
            <div class="row">
              <button
                v-if="checkPermission('ORDER_SOURCE_INSERT')"
                v-bind:style="btnCreate"
                type="button"
                class="btn btn-primary font-weight-bolder btn-sm button"
                @click="addRow"
              >
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
              </button>
            </div>
          </template>
          <template v-slot:preview>
            <div class="col-md-6 mb-5">
              <b-form>
                <div class="row">
                  <b-input
                    size="sm"
                    placeholder="Tìm kiếm"
                    v-model="search"
                    append-icon="search"
                    single-line
                    hide-details
                    class="col-md-6"
                    @keyup="fetchOrderResource"
                    v-on:keyup.enter="fetchOrderResource"
                  ></b-input>
                  <div class="col-6">
                    <div class="d-flex justify-content-end"></div>
                  </div>
                </div>
              </b-form>
            </div>

            <table
              class="table table-bordered table-vertical-center table-hover col-6"
            >
              <thead>
                <tr>
                  <th style="textAlign: center">STT</th>
                  <th scope="col">Tên</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody v-for="(item, index) in desserts" :key="index">
                <OrderResourceItem
                  v-bind:orderItem="item"
                  v-on:updateItem="updateOrderResource"
                  v-bind:listFeature="listFeature"
                  v-on:deleteItem="deleteOrderResource"
                  v-on:addItem="addNewOrderResource"
                  v-on:cancelAdd="cancelAdd"
                />
              </tbody>
            </table>
            <div class="col-md-6 row d-flex justify-content-between">
              <p class="mt-3 text-dark" style="padding: 0;font-weight: 500">
                Tổng số nguồn đơn hàng:
                {{ totalItem }}
              </p>

              <div class="d-flex justify-content-end">
                <b-col>
                  <b-pagination-nav
                    style="margin-right: -40px;"
                    class="customPagination"
                    v-if="numberOfPage >= 2"
                    :link-gen="linkGen"
                    :number-of-pages="numberOfPage"
                    use-router
                    @change="fetchOrderResource"
                    align="right"
                    first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                    prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                    next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                    last-class="page-item-last btn btn-icon btn-sm my-1 "
                    page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                  >
                    <template v-slot:first-text>
                      <span>
                        <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                      </span>
                    </template>

                    <template v-slot:prev-text>
                      <i class="ki ki-bold-arrow-back icon-xs"></i>
                    </template>

                    <template v-slot:next-text>
                      <i class="ki ki-bold-arrow-next icon-xs"></i>
                    </template>

                    <template v-slot:last-text>
                      <span class="text-info">
                        <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                      </span>
                    </template>
                  </b-pagination-nav>
                </b-col>
              </div>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<style scoped>
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}
</style>

<script>
import OrderResourceItem from '../../components/OrderResourceItem.vue';
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import SavaDataUtil from './../../../utils/saveDataToLocal';
import ApiService from '@/core/services/api.service';
import Loader from '@/view/content/Loader.vue';

export default {
  data() {
    return {
      btnCreate: {
        fontWeight: '600!important',
        'margin-right': '-10px'
      },
      search: '',
      desserts: [],
      itemChoice: null,
      listFeature: [],
      linkGen: pageNum => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      numberOfPage: null,
      totalItem: 0
    };
  },
  components: {
    KTCodePreview,
    Loader,
    OrderResourceItem
  },
  created() {
    this.getListFeature();
    this.fetchOrderResource();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nguồn đơn hàng', route: '/order-source' },
      { title: 'Danh sách nguồn đơn hàng' }
    ]);
  },
  methods: {
    getListFeature() {
      this.listFeature = SavaDataUtil.getData('listFeature');
    },
    checkPermission(condition) {
      if (this.listFeature.indexOf(condition) > -1) {
        return true;
      } else {
        return false;
      }
    },
    addRow() {
      let data = {
        id: '',
        name: '',
        createAt: ''
      };
      if (this.desserts[0].name != '') {
        this.desserts.unshift(data);
      }
    },
    cancelAdd() {
      this.desserts.shift();
    },
    fetchOrderResource() {
      this.page = this.$route.query.page;

      if (!this.page) {
        this.page = 1;
      }
      ApiService.setHeader();
      ApiService.get(
        `orderSources?name=${this.search}&limit=10&page=${this.page}`
      ).then(({ data }) => {
        this.desserts = [];
        data.data.list_order_source.forEach((item, index) => {
          let ordersource = {
            count:
              data.data.in_page >= 2
                ? index + 1 + data.data.in_page * 10 - 10
                : index + 1,
            id: item.id,
            name: item.name,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt
          };
          this.desserts.push(ordersource);
        });
        if (data.data.total_page == 1) {
          this.linkGen(1);
        }
        this.numberOfPage = data.data.total_page;
        this.totalItem = data.data.total_row;
      });
    },
    addNewOrderResource(item) {
      let data = {
        data: { name: item.name }
      };
      ApiService.setHeader();
      ApiService.post('orderSources', data).then(() => {
        this.fetchOrderResource();
      });
    },
    updateOrderResource(item) {
      let data = {
        data: { id: item.id, name: item.name }
      };
      ApiService.setHeader();
      ApiService.put(`orderSources?id=${item.id}`, data).then(() => {
        this.fetchOrderResource();
      });
    },
    deleteOrderResource(id) {
      ApiService.setHeader();
      ApiService.delete(`orderSources?id=${id}`).then(() => {
        this.fetchOrderResource();
      });
    }
  }
};
</script>
