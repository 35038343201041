<template>
  <tr class="heighRow">
    <td style="width: 10%;">
      <div class="d-flex justify-content-center">
        <span v-text="orderItem.count" class="text-right"></span>
      </div>
    </td>
    <td class="textWidth" style="padding: 0 20px 0 20px">
      <div v-if="edit">
        <input
          style="height: calc(1.35rem + 1.1rem + 2px); border-color: #007bff"
          v-model="itemChoice.name"
          placeholder="Tên người mua"
          class="w-100 h-90 input"
          oninvalid="alert('Nhập đầy đủ thông tin!');"
        />
        <p style="color: red;" v-if="error">Hãy nhập đầy đủ thông tin</p>
      </div>
      <span v-else>{{ orderItem.name }}</span>
    </td>

    <td>
      <template v-if="edit">
        <div class="d-flex justify-content-center">
          <v-icon
            class="mr-2 text-primary"
            style="font-size: 18px"
            @click="checkvalidation()"
          >mdi-check</v-icon>
          <v-icon class="text-danger" style="font-size: 18px" @click="cancelEdit()">mdi-close</v-icon>
        </div>
      </template>

      <template v-else>
        <div class="d-flex justify-content-center">
          <b-dropdown size="sm" id="dropdown-left" no-caret right>
            <template slot="button-content">
              <i style="font-size: 1rem;padding-right: 0px;" class="flaticon2-settings"></i>
            </template>
            <b-dropdown-item @click="editItem()" v-show="checkPermission('ORDER_SOURCE_UPDATE')">
              <span style="color: #3f4254; font-size: 12px">
                <i style="font-size: 1rem" class="flaticon2-pen"></i>
                &nbsp; Chỉnh sửa
              </span>
            </b-dropdown-item>
            <b-dropdown-item @click="showDeleteAlert" v-show="checkPermission('ORDER_SOURCE_DELETE')">
              <span style="color: #3f4254; font-size: 12px">
                <i style="font-size: 1rem; color: #d33" class="flaticon2-rubbish-bin-delete-button"></i>
                &nbsp; Xóa
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </td>
  </tr>
</template>

<style>
.heighRow {
  height: 40px;
}
.textWidth {
  width: 70%;
}
.input {
  border: 1px solid #ecf0f3;
  border-radius: 5px;
  padding-left: 5px;
}

*:focus {
  outline: none;
}
</style>

<script>
import Swal from 'sweetalert2';

export default {
  props: ['orderItem', 'listFeature'],
  data() {
    return {
      edit: false,
      itemChoice: {
        id: 0,
        code: '',
        name: '',
        createBy: '',
        createDate: ''
      },
      error: false
    };
  },
  updated() {
    this.checkAddRow();
  },
  methods: {
    editItem() {
      this.edit = true;
      this.itemChoice.id = this.orderItem.id;
      this.itemChoice.code = this.orderItem.code;
      this.itemChoice.name = this.orderItem.name;
      this.itemChoice.createBy = this.orderItem.createBy;
      this.itemChoice.createDate = this.orderItem.createDate;
    },
    cancelEdit: function() {
      this.error = false;
      this.edit = false;
      if (this.orderItem.name == '') {
        this.$emit('cancelAdd');
      }
      this.itemChoice.id = 0;
      this.itemChoice.code = 0;
      this.itemChoice.name = '';
      this.itemChoice.createBy = '';
      this.itemChoice.createDate = '';
    },
    updateItem: function() {
      this.edit = false;
      if (this.orderItem.id == 0) {
        this.orderItem.name = this.itemChoice.name;
        this.$emit('addItem', this.itemChoice);
      } else {
        this.$emit('updateItem', this.itemChoice);
      }
      this.itemChoice.code = 0;
      this.itemChoice.name = '';
      this.itemChoice.createBy = '';
      this.itemChoice.createDate = '';
    },
    checkvalidation() {
      if (this.itemChoice.name) {
        this.error = false;
        this.updateItem();
      } else {
        this.error = true;
      }
    },
    showDeleteAlert: function() {
      Swal.fire({
        title: 'Xóa nguồn đơn hàng!',
        text: 'Bạn có chắc muốn xóa nguồn đơn hàng này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then(result => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', this.orderItem.id);
        }
      });
    },
    checkPermission(condition) {
      if (this.listFeature.indexOf(condition) > -1) {
        return true;
      } else {
        return false;
      }
    },
    checkAddRow() {
      if (this.orderItem.name == '') {
        this.edit = true;
      } else if (this.edit) {
        this.edit = true;
      } else {
        this.edit = false;
      }
    }
  }
};
</script>
